class Screens {

	constructor(options = []){
		this.screens = document.querySelectorAll('.screen');

		this.screen = this.getScreen();

		window.addEventListener('scroll', event => {
			this.screen = this.getScreen();
		});
	}

	//moveNext();
	
	getPos() {
		return window.scrollY;
	}

	getScreens() {
		return this.screens;
	}
	getCurrent(){
		return this.screen;
	}
	getCurrentNumber(){
		return this.screen+1;
	}

	getScreen() {
		let cur_screen = null;

		/*this.screens.forEach(function(el,i){
			if (el.offsetTop <= this.getPos()
				&& el.offsetTop + el.offsetHeight >= this.getPos())
				cur_screen = i;
		})
*/
		for(let i = 0; i < this.screens.length; i++) {
			let el = this.screens[i];
			if (el.offsetTop <= this.getPos()
				&& el.offsetTop + el.offsetHeight >= this.getPos())
				cur_screen = i;
		}
		return cur_screen;
	}

	moveTo(indx){
		window.scrollTo({
			top: this.screens[indx].offsetTop,
			left: 0,
			behavior: 'smooth'
		});
	}

	moveNext() {
		let indx = (this.screen+1 >= this.screens.length) ? 0 : this.screen+1;


		this.moveTo(indx);
	}
}
