function init() {

	let myMap = new ymaps.Map("map", {
        center: [55.6329,37.3714],
        zoom: 14
    });
	// coords: 55.6350,37.3458

    myMap.behaviors.disable('scrollZoom');
    //myMap.behaviors.disable('drag');
    myMap.controls.remove('geolocationControl');
	myMap.controls.remove('searchControl');
	myMap.controls.remove('trafficControl');
	myMap.controls.remove('typeSelector');
	myMap.controls.remove('fullscreenControl');
	myMap.controls.remove('rulerControl');

	let myPlacemark = new ymaps.Placemark([55.6350,37.3458], {
        balloonContent: 'г. Москва, Боровское шоссе, д. 56, Клиника “МедиАрт”'
    }, {
        // Опции.
        iconLayout: 'default#image',
        iconImageHref: 'img/icon_map.svg',
        iconImageSize: [38, 57],
        iconImageOffset: [-19, -57]
    });

    myMap.geoObjects.add(myPlacemark);
}
ymaps.ready(init);

jQuery(document).ready(function ($) {
	$('.button').click(function(e){
		e.preventDefault();
	})

	$('.form-callback').click(function(){
		$(this).removeClass('active');
	})
	$('.callback').click(function(){
		$('.form-callback').addClass('active');
	})

	$('.reviews-list > .slider-in').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		nav: false,
		fade: true,
		arrows: true,
		nextArrow: $(".reviews-list .next"),
		prevArrow: $(".reviews-list .prev"),
		dotsClass: 'bullets'
	});

	$('.preim-list > .slider-in').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		nav: false,
		fade: true,
		arrows: true,
		nextArrow: $(".preim-list .next"),
		prevArrow: $(".preim-list .prev"),
		dotsClass: 'bullets'
	});


	const Screens1 = new Screens();
	$('.mouse-scroll').click(function(){
		Screens1.moveNext();
	});

// 	const menu = $(".main-header-top");
// 	const headerHeight = $('.main-header').height();
	
// 	$(window).scroll(function() {
// 		let top = $(window).scrollTop();
		
// 		if (top > headerHeight) {
// 			$(menu).addClass('main-header-top--fixed');
// 		}
// 		else {
// 			$(menu).removeClass('main-header-top--fixed');
// 		}
// 	});



// 	const $slider = $(".main-slider");
// 	if ($slider.length) {
// 		let currentSlide;
// 		let slidesCount;
// 		let updateSliderCounter = function (slick, currentIndex) {
// 			currentSlide = slick.slickCurrentSlide() + 1;
// 			slidesCount = slick.slideCount;
// 			$(".current").text(currentSlide);
// 			$(".size").text(slidesCount);
// 		};

// 		$slider.on("init", function (event, slick) {
// 			updateSliderCounter(slick);
// 		});

// 		$slider.on("afterChange", function (event, slick, currentSlide) {
// 			updateSliderCounter(slick, currentSlide);
// 		});

// 		$slider.slick({
// 			autoplay: true,
// 			autoplaySpeed: 5000,
// 			dots: true,
// 			nav: false,
// 			fade: true,
// 			arrows: false,
// 			nextArrow: $(".next"),
// 			prevArrow: $(".prev"),
// 			dotsClass: 'bullets'
// 		});
// 	}
// 	$('.reviews-list.slider').slick({
// 		autoplay: true,
// 		autoplaySpeed: 5000,
// 		dots: true,
// 		nav: false,
// 		fade: true,
// 		arrows: false,
// 		nextArrow: $(".next"),
// 		prevArrow: $(".prev"),
// 		dotsClass: 'bullets'
// 	})
// 	// if ($innerSlider.length) {
// 	// 	let currentSlide;
// 	// 	let slidesCount;
// 	// 	let updateSliderCounter = function (slick, currentIndex) {
// 	// 		currentSlide = slick.slickCurrentSlide() + 1;
// 	// 		slidesCount = slick.slideCount;
// 	// 		$(".current").text(currentSlide);
// 	// 		$(".size").text(slidesCount);
// 	// 	};

// 	// 	$innerSlider.on("init", function (event, slick) {
// 	// 		updateSliderCounter(slick);
// 	// 	});

// 	// 	$innerSlider.on("afterChange", function (event, slick, currentSlide) {
// 	// 		updateSliderCounter(slick, currentSlide);
// 	// 	});

// 	// 	$innerSlider.slick({
// 	// 		autoplay: false,
// 	// 		autoplaySpeed: 5000,
// 	// 		dots: false,
// 	// 		nav: true,
// 	// 		fade: true,
// 	// 		arrows: true,
// 	// 		nextArrow: $(".next"),
// 	// 		prevArrow: $(".prev")
// 	// 	});
// 	// }

// 	$(".partners-list.slider").slick({
// 		autoplay: false,
// 		autoplaySpeed: 5000,
// 		arrows: true,
// 		infinite: true,
// 		speed: 300,
// 		slidesToShow: 5,
// 		slidesToScroll: 1,
// 		dots: true,

// 		// centerMode: true,
// 		nextArrow: $(".partners-section .next"),
// 		prevArrow: $(".partners-section .prev"),
// 		dotsClass: 'bullets',
// 		responsive: [
// 			{
// 				breakpoint: 1160,
// 				settings: {
// 					slidesToShow: 2,
// 					slidesToScroll: 1,
// 					infinite: true,
// 					dots: true
// 				}
// 			},

// 			{
// 				breakpoint: 800,
// 				settings: {
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 					infinite: true,
// 					dots: true
// 				}
// 			}
// 		]
// 	});

// 	$(".product-list.slider").slick({
// 		autoplay: false,
// 		autoplaySpeed: 5000,
// 		// arrows: true,
// 		infinite: false,
// 		speed: 300,
// 		slidesToShow: 4,
// 		slidesToScroll: 1,
// 		dots: true,
// 		arrows:false,
// 		// centerMode: true,
// 		// nextArrow: $(".partners-section .next"),
// 		// prevArrow: $(".partners-section .prev"),
// 		dotsClass: 'bullets',
// 		responsive: [
// 			{
// 				breakpoint: 1160,
// 				settings: {
// 					slidesToShow: 3,
// 					slidesToScroll: 1,
// 					infinite: false,
// 					dots: true
// 				}
// 			},

// 			{
// 				breakpoint: 800,
// 				settings: {
// 					slidesToShow: 2,
// 					slidesToScroll: 1,
// 					infinite: false,
// 					dots: true
// 				}
// 			},
// 			{
// 				breakpoint: 400,
// 				settings: {
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 					infinite: false,
// 					dots: true
// 				}
// 			}
// 		]
// 	});

// 	$('.article-gallery.slider').slick({
// 		autoplay: false,
// 		autoplaySpeed: 5000,
// 		infinite: true,
// 		speed: 300,
// 		slidesToShow: 3,
// 		// variableWidth: true,
// 		slidesToScroll: 1,
// 		dots: true,
// 		dotsClass: 'bullets',
// 		responsive: [
// 			{
// 				breakpoint: 1000,
// 				settings: {
// 					slidesToShow: 2,
// 					slidesToScroll: 1,
// 					infinite: true,
// 					dots: true
// 				}
// 			},
// 			{
// 				breakpoint: 700,
// 				settings: {
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 					infinite: true,
// 					dots: true
// 				}
// 			},
// 		]




// 	});





// 	$('.slider-gallery').slick({
// 		autoplay: false,
// 		autoplaySpeed: 5000,
// 		infinite: true,
// 		speed: 300,
// 		slidesToShow: 4,
// 		// variableWidth: true,
// 		slidesToScroll: 1,
// 		dots: true,
// 		dotsClass: 'bullets',
// 		responsive: [
// 			{
// 				breakpoint: 700,
// 				settings: {
// 					slidesToShow: 3,
// 					slidesToScroll: 1,
// 					infinite: true,
// 					dots: true
// 				}
// 			},
// 			{
// 				breakpoint: 440,
// 				settings: {
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 					infinite: true,
// 					dots: true
// 				}
// 			},
// 		]




// 	});
// 	function adaptiveSliderInit(slider){
// 		let width = $(window).width();
// 		let advSlider = $(slider);
// 		if (!advSlider.hasClass('slick-initialized')){
// 			if (width < 700) {
// 				advSlider.slick({
// 					autoplay: true,
// 					autoplaySpeed: 5000,
// 					dots: true,
// 					nav: false,
// 					// fade: true,
// 					arrows: false,
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 					nextArrow: $(".next"),
// 					prevArrow: $(".prev"),
// 					dotsClass: 'bullets',
// 				})
// 			} else {
// 				if(advSlider.hasClass('slick-initialized')) {
// 					advSlider.slick('unslick');
// 				}
				
// 			}
// 		} else {
// 			if (width < 700) {

// 			}
// 			else {
// 				advSlider.slick('unslick');
// 			}
// 		}
		
// 	}
// 	$(".services-head").click((e) => {
				
// 		let elementClick = $(e.currentTarget);
		
// 		let menu = elementClick.siblings('.services-body');
// 		if ($(elementClick).hasClass('sliding')) {
// 			menu.slideToggle();
// 			elementClick.toggleClass('rotate')
// 		} else {
			
// 		}
		
		
	
// 	});
// 	// $(".services-head").click((e) => {
				
// 	// 	let elementClick = $(e.currentTarget);
		
// 	// 	let slide = elementClick.siblings('.services-item-prices');
		
// 	// 		slide.slideToggle();
// 	// 		elementClick.toggleClass('rotate')
		
		
		
	
// 	// });
// 	function sectionMenuHandler(){
// 		let width = $(window).width();
// 		if (width < 500) {
// 			$('.services-head').addClass('sliding');
// 			$('.services-head').siblings('.services-body').slideUp();
			
// 		} else {
// 			$('.services-head').removeClass('sliding');
// 			$('.services-head').siblings('.services-body').slideDown();
// 		}
// 	}


// // INIT BLOCK

// 	// adaptiveSliderInit($('.programms-list'));
// 	// adaptiveSliderInit($('.advantages-list'));
// 	sectionMenuHandler();
	 
// 	$(window).resize(() => {
		
// 		// adaptiveSliderInit($('.programms-list'));
// 		// adaptiveSliderInit($('.advantages-list'));
// 		sectionMenuHandler();
		
		

// 	});
	
// // INIT BLOCK
	
// 	$(".mouse-scroll").click((e) => {
// 		e.preventDefault();

// 		let elementClick = $(e.currentTarget).data('target');

// 		let destination = $(elementClick).offset().top;

// 		if (true) {

// 			$('html').animate({ scrollTop: destination }, 1100); //1100 - скорость

// 		} else {
// 			$('html').animate({ scrollTop: destination }, 1100);
// 		}
// 		return false;
// 	});

// 	$(".menu-button").click(e => {
// 		e.preventDefault();
// 		$(".main-nav .menu").toggleClass("menu--show");
// 		$('.burger-icon').toggleClass('burger-icon--close');
// 		$('.menu-button').toggleClass('menu-button--close');
// 	});



// 	const callbackBtn =$('.js_callback');
// 	callbackBtn.click(e => {
// 		e.preventDefault();
		
// 		openform($('.form-callback'));
	
// 	})

// 	$('.overlay').on('click', e => {
		
		
// 		if ($(e.target).hasClass('overlay--show')) {
// 			let form = $(e.target).find('.form');
// 			closeform(form);
			
// 		}
		
		
		
		
	
// 	});

// 	openform = (form) => {

// 		form.closest('.overlay').addClass('overlay--show');
// 		form.append('<div class="form-close"></div>');
// 		$('.form-close').on('click', e => {
			
// 			let form = $(e.target).closest('.form');
// 			closeform(form);
// 		})
// 		// form.find('input[name="FIELDS[HIDDEN]"]').val(window.location.href);
		
		
	
// 	}
// 	closeform = (form) => {
	
// 		form.closest('.overlay').removeClass('overlay--show');
		
// 		$('.form-close').remove();
// 	}






// 	if ($('.tab').length > 0) {
// 		$('.tab').click(function () {
// 			$('.tab').removeClass('active');
// 			$('.tab-content').removeClass('active');
// 			$(this).addClass('active');
// 			$('.tab-content').eq($(this).index()).addClass('active');
// 		});
// 	}

	
// 	$('.section-item-title').on('click',(e)=>{
// 		e.preventDefault();
// 		let target = e.currentTarget;
// 		$(target).siblings('.section-child-list').slideToggle();
// 		$(target).toggleClass('rotate');
// 	});


// 	$('.js_buy').on('click',(e)=>{
// 		e.preventDefault();
// 		let target = e.currentTarget;
// 		console.log($(target).data('id'));

// 		let data = {
// 			productId: $(target).data('id'),
			
			
// 		};

// 		$.ajax({
		
// 			url: '/local/templates/glazovpack/ajax-handler.php',
// 			data: data,
// 			success: function(data) {
				
// 				alert("Товар добавлен в корзину")
				
				
				
// 			},
// 			error: function(err){
// 				console.log(err)
// 			},
		
// 			dataType:'json'
// 		});
// 	});







});

